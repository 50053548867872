/* LineChartLoader.css */

.chart-container {
   width: 100%;
   height: 500px;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   background-color: #f0f0f0;
   border-radius: 8px;
   position: relative;
}

.line-placeholder {
   position: relative;
   width: 90%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-around;
}

.line {
   width: 15%;
   height: 0;
   border-bottom: 2px solid #ccc;
   opacity: 0.5;
   animation: loadingAnimation 1.5s ease-in-out infinite;
}

.line1 {
   animation-delay: 0s;
}
.line2 {
   animation-delay: 0.2s;
}
.line3 {
   animation-delay: 0.4s;
}
.line4 {
   animation-delay: 0.6s;
}
.line5 {
   animation-delay: 0.8s;
}

@keyframes loadingAnimation {
   0%,
   100% {
      height: 0;
      opacity: 0.3;
   }
   50% {
      height: 100px;
      opacity: 1;
   }
}
