body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  background-color: #000950;
  color: white;
}

.pending {
  background-color: #000950;
  color: white;
}

.subRoute-active {
  background-color: rgb(51, 58, 115);
  color: white;
}

.navlink {
  color: black;
}

