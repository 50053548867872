.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
  }
  .video-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 
  
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
  
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
  top: 50%;
}

.video-container .logo {
  z-index: 1;
  position: absolute;
  top: 5%;
  left: 5%;
}
